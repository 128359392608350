<template>
  <div id="dropbox">
    <div class="dropboxOptions" v-if="jahresauswahl">
      <select v-model="usedYear" class="yearchooser">
        <option selected disabled hidden :value="null">Bitte Jahr wählen</option>
        <option v-for="jahr in jahre" :value="jahr" :key="jahr">
          {{ jahr }}
        </option>
      </select>
      <v-checkbox
          v-if="checkbox"
          class="yearCheckbox"
          v-model="isChecked"
          :label="'2-jährliche Aktualisierung (städtebaulich)'"
      ></v-checkbox>
    </div>
    <div class="dropboxActions">
      <file-pond
          :disabled=!enable
          ref="pond"
          allow-multiple="false"
          v-bind:server="server"
          v-bind:files="myFiles"
          v-on:warning="warning"
          v-on:addfile="onLoad"
          max-files="1"
          :label-idle=dropboxTitel
          :aria-label=dropboxTitel
          acceptedFileTypes="application/zip, application/x-zip-compressed"
          labelFileTypeNotAllowed="Ungültiges Dateiformat"
          fileValidateTypeLabelExpectedTypes="Erwartet wird ein zip-Archiv"
          labelInvalidField="Ungültige Datei"
          labelFileLoading="Datei wird hochgeladen"
          labelFileProcessing="Datei wird hochgeladen"
          labelFileLoadError="Fehler beim Hochladen"
          labelFileProcessingComplete="Datei wurde hochgeladen"
          labelFileProcessingAborted="Hochladen wurde abgebrochen"
          labelFileProcessingError="Fehler beim Hochladen"
          labelTapToCancel="Klicken zum Abbruch"
          labelTapToUndo="Klicken zum entfernen"
          labelButtonRemoveItem="Löschen"
          labelButtonAbortItemLoad="Abbruch"
          labelButtonAbortItemProcessing="Abbruch"

      />
      <p class="warningMessage">{{warningMessage}}</p>
    </div>

  </div>
</template>

<script>
// Import Vue FilePond
import vueFilePond from "vue-filepond";
// Import FilePond styles
import "filepond/dist/filepond.css";

// Import plugins
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type/dist/filepond-plugin-file-validate-type.esm.js';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.esm.js';

// Import styles
import 'filepond/dist/filepond.min.css';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.min.css';

//FME-Urls
import services from "@/config/services";
//FME_Request
import{fmeManager} from "../js/fme/fmeManager";

// Create FilePond component
const FilePond = vueFilePond(FilePondPluginFileValidateType, FilePondPluginImagePreview);
export default {
  name: "dropbox",
  props: {
    dropboxNumber:{
      type: Number
    },
    projectname:{
      type: String
    },
    workspace:{
      type: String
    },
    token:{
      type: String
    },
    pw:{
      type: String
    },
    username:{
      type: String
    },
    dropboxTitel:{
      type: String
    },
    jahresauswahl:{
      type: Boolean
    },
    checkbox:{
      type: Boolean
    }
  },
  data: function() {
    return {
      jahre: this.createYearList(),
      usedYear: null,
      isChecked: true,
      //Dropbox enable
      enable: true,
      warningMessage: "",
      //FME-Backend
      server: {
        url: services.servername,
            process: {
              onload: this.uploadSucess,
              onerror: this.uploadFail,
              url: "/fmerest/v3/resources/connections/FME_SHAREDRESOURCE_TEMP/filesys/"+this.projectname+"/" + new Date().getTime() + "?createDirectories=true&overwrite=true",
              method: 'POST',
              withCredentials: true,
              headers: {
                "Accept": "application/json",
                "Authorization": "fmetoken token="+this.token,
                "Content-Disposition": 'attachment; filename="borisbw-upload.zip"',
                "Content-Type": "application/octet-stream"
              }
        },
    },
      //Zwischenspeicher für Filepond
      myFiles: [],
      //Serverantwort nach Upload
      jsonResponse: ""
    };
  },
  methods: {
    createYearList(){
      let years = [];
      let yearCount = 2011
      let jetzt = new Date().getFullYear();
      while ( yearCount <= jetzt){
        years.push(yearCount);
        yearCount++;
      }
      return years
    },
    /**
     * Methode um Vue-Parent anzuzeigen, dass ein File-Upload stattfindet
     */
    onLoad: function (error, file){
      //Upload ohne Jahresauswahl
      if(!this.jahresauswahl){
        this.$emit("startLoading",{identifier: this.dropboxNumber});
      //Upload mit Jahresauswahl
      }else if(this.jahresauswahl && this.usedYear){
        this.$emit("startLoading",{identifier: this.dropboxNumber});
      //Upload mit Jahresauswahl - Kein Jahr gewählt
      }else{
        file.abortLoad()
        let that = this;
        this.warningMessage = "Bitte wählen Sie ein Geltungsjahr aus"
        let timeout = setTimeout(function (){
          that.warningMessage = "";
        }, 2000);
      }
    },

    /**
     * Methode um dem User zu zeigen, dass nur eine Datei gleichzeitig hochgeladen werden darf
     * @param e
     */
    warning: function (e) {
      if(e?.body === "Max files"){
        let that = this;
        this.warningMessage = "Bitte laden Sie nur eine Datei hoch"
        let timeout = setTimeout(function (){
          that.warningMessage = "";
        }, 2000);
      }
    },
    /**
     * Methode um nach erfolgreichem Upload FME-Prozess zu starten
     * @param response
     */
    uploadSucess: function (response){
      //deaktiviert FilePond
      this.enable = false;
      //Entfernt alle Datein
      this.$refs.pond.removeFiles({ revert: true });
      //Signalisiert Parent -> Loading
      this.$emit("startProcess",{identifier: this.dropboxNumber, status: true});
      //Speichert Scope
      let that = this;
      //ErrorHandling JSON.Parse
      try {
        //Hole Response und wandle in Json um
        let jsonResponse = JSON.parse(response);
        //Erstelle Result-Url
        let resulturl = "/" +this.projectname+"/" + new Date().getTime() + "/Ergebnis.zip";
        //let apiToken ='e1fa96d1b6c71d7075fc97c3e34f670366e3badd';
        /**
         * Starte asynchronen FME-Transfer
         * Fehlercode 01: JSON-Parse-Fehler beim Upload-Response
         * Fehlercode 02: FME-Transferjob fehlgeschlagen (Ungeplant)
         * Fehlercode 03: FME-Transferjob gibt keine Job-ID zurück
         * Fehlercode 04: FME-Transferjob fehlgeschlagen (Geplant)
         * Fehlercode 05: FME GetResult Fehlgeschlagen
         * Fehlercode 06: Kein Download-Link im Resultresponse
         */
        fmeManager.fmeTransfer(this.pw, this.username,this.token , this.projectname, this.workspace, jsonResponse, resulturl, this.usedYear, this.isChecked).then(function (result) {
              //Gibt es eine JobID
              if(result){
                //Starte Intervall
                let intervall =  setInterval(function() {
                  //Frage nach Jobstatus
                  fmeManager.fmegetJobStatus(that.token,result.data.id).then(function (jobstatus) {
                    //Wenn erfolgreich
                    if(jobstatus.data.status === "SUCCESS") {
                      //entferne Intervall
                      clearInterval(intervall);
                      //Hole JobResult
                      fmeManager.fmegetJobResult(that.token,resulturl).then(function (response) {
                        if(response.config.url){
                          //Entfernt File in Pond
                          that.$refs.pond.removeFiles({ revert: true });
                          //aktiviert FilePond
                          that.enable = true;
                          //Signalisiert Parent -> Donwloadlink fertig
                          that.$emit("result",{identifier: that.dropboxNumber, fileUrl: response.config.url});
                        }else{
                          that.errorHandling("Fehlercode 06");
                        }
                      }).catch(function (error) {
                        that.errorHandling("Fehlercode 05");
                      });
                    }
                    //Wenn gescheitert
                    if(jobstatus.data.status === "FME_FAILURE"){
                      //entferne Intervall
                      clearInterval(intervall);
                      //Fehlerbehandlung
                      that.errorHandling("Fehlercode 04");
                    }
                  });
                },3000);
                //Ohne JobID ist FME fehlgeschlagen
              }else{
                that.errorHandling("Fehlercode 03");
              }
            }
        ).catch(function (error) {
          that.errorHandling("Fehlercode 02");
        });
      }catch (error){
        that.errorHandling("Fehlercode 01");
      }
    },

    /**
     * Methode um Fehlel während des FME-Prozessings abzufangen und weiterzuleiten
     * @param message
     */
    errorHandling: function (message){
      this.enable = true;
      this.$emit("showError",{identifier: this.dropboxNumber, message: "Die Verarbeitung ist fehlgeschlagen ("+ message+"). Bitte wenden Sie sich an den oben verlinkten Kontakt."});
    },

    /**
     * Methode um Fehler während des File-Uploads abzufangen und weiterzuleiten
     * @param response
     */
    uploadFail: function (response){
      //Entfernt File in Pond
      this.$refs.pond.removeFiles({ revert: true });
      this.$emit("showError",{identifier: this.dropboxNumber, message: "Der Upload ist fehlgeschlagen. Bitte wenden Sie sich an den oben verlinkten Kontakt."});
    }
  },
  components: {
    FilePond,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import '../css/style.css';

</style>