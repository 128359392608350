<template>
    <div class="templateBody">
      <div id="anwendungsheader">
        <bw_header></bw_header>
        <Navigation></Navigation>
      </div>
      <div id="maincontainer" class="clearfix">
        <Kontaktcontent></Kontaktcontent>
      </div>
      <bw_footer></bw_footer>
    </div>
</template>

<script>
import bw_header from '../components/header'
import Navigation from '../components/Navigation'
import Kontaktcontent from "@/components/Kontaktcontent";
import bw_footer from '../components/footer'
export default {
  name: "kontakt",
  components: {
    bw_header,
    Navigation,
    Kontaktcontent,
    bw_footer
  }
}
</script>

<style>
@import '../css/style.css';
</style>