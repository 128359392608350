<template>
  <div class="impressumDiv">
    <h1>Impressum</h1>

    <h2>Verantwortlichkeit:</h2>
    <span>
          Anbieter dieser Seiten ist das
          <p>
            <b>Landesamt für Geoinformation und Landentwicklung Baden-Württemberg</b><br>
            Büchsenstraße 54<br>
            70174 Stuttgart<br>
            vertreten durch den Präsidenten Dieter Ziesel.<br>
            Verantwortlicher Redakteur: Ulf Jackisch, Büchsenstraße 54, 70174 Stuttgart.
          </p>

          Mit dem Landesamt für Geoinformation und Landentwicklung Baden-Württemberg können Sie Kontakt aufnehmen:
          <p>per E-Mail: <a href="mailto:poststelle@lgl.bwl.de?subject=[Mail aus Uploadportal Grundsteuer B Baden-Württemberg]">poststelle@lgl.bwl.de</a><br>
          über Telefon: +49 (0)711 95980-0<br>
          per Fax: +49 (0)711 95980-700<br>
          </p>
          Umsatzsteueridentifikationsnummer gem. § 27a UmStG: DE147794090
          </span>

    <h2>Entwicklung & Betrieb</h2>
    <span>
          <p>
            Information und Technik Nordrhein-Westfalen (IT.NRW)<br>
          - Geoinformationszentrum -<br>
          Mauerstraße 51<br>
          40476 Düsseldorf<br>
          E-Mail: <a href="mailto:limgeo@it.nrw.de?subject=[Mail aus Uploadportal Grundsteuer B Baden-Württemberg]">limgeo@it.nrw.de</a><br>
          Telefon: +49 (0)211-9449-6301<br>
          Internet: <a href="https://www.it.nrw" target="_blank" rel="noopener">www.it.nrw</a><br>
          </p>
        </span>

    <h2>Haftungsausschluss - Rechtliche Hinweise für die Nutzung</h2>
    <div class="haftungsausschluss">
          <span>
           <ol>
              <li>Inhalt des Onlineangebotes</li>
             <p>Die sich auf diesen Internetseiten befindlichen Texte und Fachdaten sind sorgfältig<br>
               und nach bestem Wissen ausgesucht und zusammengestellt. Dennoch kann das LGL keine Gewähr<br>
               für die Aktualität, Richtigkeit und Vollständigkeit der sich auf dieser Homepage<br>
               befindlichen Informationen übernehmen. Es behält sich ausdrücklich vor, Teile der Seiten<br>
               oder das gesamte Angebot ohne gesonderte Ankündigung abzuändern.
             </p>
              <li>Verweise und Links</li>
             <p>
               Diese Website enthält auch Links oder Verweise auf Websites Dritter. Diese Links zu den<br>
               Websites Dritter stellen keine Zustimmung zu deren Inhalten durch den Herausgeber dar.<br>
               Es wird keine Verantwortung für die Verfügbarkeit oder den Inhalt solcher Websites übernommen<br>
               und keine Haftung für Schäden oder Verletzungen, die aus der Nutzung - gleich welcher Art -<br>
               solcher Inhalte entstehen. Mit den Links zu anderen Websites vermittelt das Landesamt für<br>
               Geoinformation und Landentwicklung Baden-Württemberg den Nutzern lediglich den Zugang zur<br>
               Nutzung der Inhalte. Für illegale, fehlerhafte oder unvollständige Inhalte und für Schäden,<br>
               die aus der Nutzung entstehen, haftet allein der Anbieter der Seite, auf welche verwiesen wurde.
             </p>
              <li>Urheber- und Kennzeichenrecht</li>
             <p>
               Alle innerhalb des Internetangebotes des Landesamts für Geoinformation und Landentwicklung<br>
               genannten und gegebenenfalls durch Dritte geschützten Marken- und Warenzeichen unterliegen den<br>
               Bestimmungen des jeweils gültigen Kennzeichenrechtes und den Besitzrechten der jeweiligen<br>
               eingetragenen Eigentümer. Allein aufgrund der bloßen Nennung ist nicht der Schluss zu ziehen,<br>
               dass Markenzeichen nicht durch Rechte Dritter geschützt sind. Die Urheber- und Nutzungsrechte<br>
               an veröffentlichten, von dem Landesamt für Geoinformation und Landentwicklung selbst erstellten<br>
               Texten, Grafiken, Tondokumenten oder Videosequenzen bleibt allein bei ihm. Eine Vervielfältigung<br>
               oder Verwendung solcher Objekte in anderen elektronischen oder gedruckten Publikationen ist ohne<br>
               ausdrückliche Zustimmung des Landesamtes für Geoinformation und Landentwicklung nicht gestattet.
             </p>
             <li>Verantwortlichkeit im Sinne des Telemediengesetzes (TMG):</li>
             <p>
               Das LGL ist als Diensteanbieter nach TMG nur für die eigenen Informationen, die es zur<br>
               Nutzung bereithält, nach den allgemeinen Gesetzen verantwortlich. Die Haftung für Schäden<br>
               materieller oder ideeller Art, die durch die Nutzung der Informationen verursacht wurden,<br>
               ist ausgeschlossen, sofern nicht Vorsatz oder grobe Fahrlässigkeit vorliegt. Soweit ein Text von<br>
               dritter Seite erstellt ist, wird der jeweilige Verfasser namentlich benannt. In diesen Fällen<br>
               ist der Verfasser des jeweiligen Dokuments bzw. sein Auftraggeber für den Inhalt verantwortlich.<br>
               Für durch nicht fehlerfrei angelegte Dateien oder nicht fehlerfrei strukturierte Formate bedingte<br>
               Unterbrechungen oder anderweitige Störungen kann das LGL keine Gewähr übernehmen. Das LGL<br>
               übernimmt für derartige Störungen beim Aufsuchen der Webseite und Links zu fremden Webseiten<br>
               keine Verantwortung.
             </p>
           </ol>
        </span>
    </div>

  </div>
</template>

<script>
export default {
  name: "impressumscontent"
}
</script>

<style scoped>
@import '../css/style.css';
</style>