<template>
  <div class="templateBody">
    <div id="anwendungsheader">
      <bw_header></bw_header>
      <Navigation></Navigation>
    </div>
    <div id="maincontainer" class="clearfix">
      <wellcomeMessage></wellcomeMessage>
        <div id="uploadContainer">
          <div class="dropboxContainer validate">
            <Dropbox v-bind:dropboxNumber="1" v-bind:jahresauswahl="true" v-bind:checkbox="false" v-bind:token='token' v-bind:pw='pwVal'
                     v-bind:username='username' v-bind:projectname='projectname1'  v-bind:workspace='workspace1' v-bind:dropboxTitel="titel1"
                     v-on:startLoading="setLoading" v-on:startProcess="setProcess" v-on:result='showResult' v-on:showError='showError'>
            </Dropbox>
            <v-btn
                v-if="!processing1&& ready1"
                :disabled=!ready1
                color="blue-grey"
                class="ma-2 white&#45;&#45;text"
                :href=href1
            >
              Download
              <v-icon right dark>download</v-icon>
            </v-btn>
            <div class="loadingDiv" v-if="processing1">
              <p>Datei wird verarbeitet. Bitte Warten</p>
              <v-progress-circular
                  indeterminate
                  color="#95be0f"
              ></v-progress-circular>
            </div>
            <p class="errorMessage">{{message1}}</p>
          </div>
          <div v-if="getStadtbauRight" class="dropboxContainer validateUpload bw">
            <Dropbox v-bind:dropboxNumber="2" v-bind:jahresauswahl="true" v-bind:checkbox="true" v-bind:token='token' v-bind:pw='pwStadt'
                     v-bind:username='username' v-bind:projectname='projectname1'  v-bind:workspace='workspace2' v-bind:dropboxTitel="titel2"
                     v-on:startLoading="setLoading" v-on:startProcess="setProcess" v-on:result='showResult' v-on:showError='showError'>
            </Dropbox>
            <v-btn
                v-if="!processing2&& ready2"
                :disabled=!ready2
                color="blue-grey"
                class="ma-2 white&#45;&#45;text"
                :href=href2
            >
              Download
              <v-icon right dark>download</v-icon>
            </v-btn>
            <div class="loadingDiv" v-if="processing2">
              <p>Datei wird verarbeitet. Bitte Warten</p>
              <v-progress-circular
                  indeterminate
                  color="#95be0f"
              ></v-progress-circular>
            </div>
            <p class="errorMessage">{{message2}}</p>
          </div>
          <div v-if="getGrundsteuerRight" class="dropboxContainer validateUpload grundsteuer">
            <Dropbox v-bind:dropboxNumber="3" v-bind:jahresauswahl="false" v-bind:checkbox="false" v-bind:token='token' v-bind:pw='pwGrund'
                     v-bind:username='username' v-bind:projectname='projectname2'  v-bind:workspace='workspace3' v-bind:dropboxTitel="titel3"
                     v-on:startLoading="setLoading" v-on:startProcess="setProcess" v-on:result='showResult' v-on:showError='showError'>
            </Dropbox>
            <v-btn
                v-if="!processing3&& ready3"
                :disabled=!ready3
                color="blue-grey"
                class="ma-2 white&#45;&#45;text"
                :href=href3
            >
              Download
              <v-icon right dark>download</v-icon>
            </v-btn>
            <div class="loadingDiv" v-if="processing3">
              <p>Datei wird verarbeitet. Bitte Warten</p>
              <v-progress-circular
                  indeterminate
                  color="#95be0f"
              ></v-progress-circular>
            </div>
            <p class="errorMessage">{{message3}}</p>
          </div>
        </div>
      <span>Das Ergebnis wird nach Prozessende hier als Download bereitgestellt</span>
      </div>
    <bw_footer></bw_footer>
  </div>
</template>

<script>
import bw_header from '../components/header'
import Navigation from '../components/Navigation'
import wellcomeMessage from '../components/wellcomeMessage'
import bw_footer from '../components/footer'
import Dropbox from '../components/dropbox'
import services from "@/config/services";
import{userstore} from "../router/userstore"
export default {
  components: {
    bw_header,
    Navigation,
    wellcomeMessage,
    bw_footer,
    Dropbox
  },
  mounted() {
    let that = this;
    /**
     * Methode um sicherzustellen dass die Vue-Component immer einen gültigen Token hat
     * @type {number}
     */
    this.tokenCheck = setInterval(function() {
      //Kein Token oder Ablaufdatum vorhanden
        if(!that.getToken() || !that.getexpiry()){
          //Hole FME-Token
            userstore.getToken().then(function (response){
              //Wenn Request Fehlschlägt
                if(!response.token) {
                  // -> Logout
                  that.router.push({name: 'Login', params: {message: response.message, visable: true}});
                }
            });
        //FME-Token ist nur noch weniger als 5 Minuten gültig
        }else if(that.compareDate(that.expiry) < 5){
          //Hole FME-Token
          userstore.getToken(that.token).then(function (response){
            //Wenn Request Fehlschlägt
              if(!response.token) {
                // -> Logout
                that.router.push({name: 'Login', params: {message: response.message, visable: true}});
              }else {
                that.expiry = response.expiry;
              }
          });
        }
    },10000);
  },
  data: function() {
    return {
      panel: 0,
      //Name des FME-Projektname
      projectname1: services.projectname_stadtbau,
      projectname2: services.projectname_grundsteuer,
      //Name der FME-Workspaces
      workspace1: services.workspace_validierung,
      workspace2: services.workspace_upload1,
      workspace3: services.workspace_upload2,
      //DropBox-Titel
      titel1: "<span class='dropBoxLabel'>Nur Validierung</span> Laden sie hier Ihre Daten hoch",
      titel2: "<span class='dropBoxLabel'>Validierung + Upload \"BORIS-BW\"</span> Laden sie hier Ihre Daten hoch",
      titel3: "<span class='dropBoxLabel'>Validierung + Upload \"Grundsteuer B\"</span> Laden sie hier Ihre Daten hoch",
      //Downloadlinks für antwort.zip
      href1: "",
      href2: "",
      href3: "",
      //Verarbeitung abgeschlossen
      ready1: false,
      ready2: false,
      ready3: false,
      //Verarbeitung noch am laufen
      processing1: false,
      processing2: false,
      processing3: false,
      //Userrückmeldung
      message1: "",
      message2: "",
      message3: "",
      //FME-Token
      token: this.getToken(),
      //Ablaufdatum des FME-Token
      expiry:this.getexpiry(),
      pwVal: this.getvalidatePassword(),
      pwGrund: this.getGrundsteuerPassword(),
      pwStadt: this.getStadtbauPassword(),
      username: this.getUsername()
    };
  },
  computed: {
    getGrundsteuerRight: function () {
      return JSON.parse(sessionStorage.getItem('grundsteuerRight'));
    },
    getStadtbauRight: function () {
      return JSON.parse(sessionStorage.getItem('stadtbauRight'));
    }
  },
  methods:{
    /**
     * Methode nach einer erfolgreichen Verarbeitung antwort.zip darzustellen
     * @param event
     */
    showResult(event){
      switch (event?.identifier) {
        case 1:
          this.processing1 = false;
          this.href1 = event.fileUrl.replace('"','')+"&fmetoken="+this.token;
          this.ready1 = true;
          break;
        case 2:
          this.processing2 = false;
          this.href2 = event.fileUrl.replace('"','')+"&fmetoken="+this.token;
          this.ready2 = true;
          break;
        case 3:
          this.processing3 = false;
          this.href3 = event.fileUrl.replace('"','')+"&fmetoken="+this.token;
          this.ready3 = true;
          break;
      }
    },

    /**
     * Methode um Fehler während des Uploads oder der Verarbeitung darzustellen
     * @param event
     */
    showError(event){
      switch (event?.identifier) {
        case 1:
          this.processing1 = false;
          this.message1 = event.message;
          break;
        case 2:
          this.processing2 = false;
          this.message2 = event.message;
          break;
        case 3:
          this.processing3 = false;
          this.message3 = event.message;
          break;
      }
    },

    /**
     * Methode um den Verarbeitungsstart anzuzeigen
     * @param event
     */
    setLoading(event){
      switch (event?.identifier) {
        case 1:
          this.ready1 = false;
          this.message1 = "";
          break;
        case 2:
          this.ready2 = false;
          this.message2 = "";
          break;
        case 3:
          this.ready3 = false;
          this.message3 = "";
          break;
      }
    },

    /**
     * Methode um den Verarbeitungsstatus anzuzeigen
     * @param event
     */
    setProcess(event){
      switch (event?.identifier) {
        case 1: this.processing1 = event?.status;break;
        case 2: this.processing2 = event?.status;break;
        case 3: this.processing3 = event?.status;break;
      }
    },

    /**
     * Methode um den momentan benutzen Token zu bekommen
     * @returns {string|null|*}
     */
    getToken(){
      //Ist der Token noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.token) return this?.$route?.params?.token;
      //Ist der Token noch in der Session gespeichert
      else if(sessionStorage.getItem('token') !== null) return sessionStorage.getItem('token');
      //Kein Token vorhanden
      else return null;
    },
    getexpiry(){
      //Ist das Ablaufdatum noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.expiry) return this?.$route?.params?.expiry;
      //Ist das Ablaufdatum noch in der Session gespeichert
      else if(sessionStorage.getItem('expiry') !== null) return sessionStorage.getItem('expiry');
      //Kein Ablaufdatum vorhanden
      else return null;
    },
    getvalidatePassword(){
      //Ist das Ablaufdatum noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.validatePassword) return this?.$route?.params?.validatePassword;
      //Ist das Ablaufdatum noch in der Session gespeichert
      else if(sessionStorage.getItem('validatePassword') !== null) return sessionStorage.getItem('validatePassword');
      //Kein Ablaufdatum vorhanden
      else return null;
    },
    getGrundsteuerPassword(){
      //Ist das Ablaufdatum noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.grundsteuerRight) return this?.$route?.params?.grundsteuerPassword;
      //Ist das Ablaufdatum noch in der Session gespeichert
      else if(sessionStorage.getItem('grundsteuerPassword') !== null) return sessionStorage.getItem('grundsteuerPassword');
      //Kein Ablaufdatum vorhanden
      else return null;
    },
    getStadtbauPassword(){
      //Ist das Ablaufdatum noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.stadtbauRight) return this?.$route?.params?.stadtbauPassword;
      //Ist das Ablaufdatum noch in der Session gespeichert
      else if(sessionStorage.getItem('stadtbauPassword') !== null) return sessionStorage.getItem('stadtbauPassword');
      //Kein Ablaufdatum vorhanden
      else return null;
    },
    getUsername(){
      //Ist das Ablaufdatum noch durch die Weiterleitung gespeichert
      if(this.$route?.params?.userName) return this?.$route?.params?.userName;
      //Ist das Ablaufdatum noch in der Session gespeichert
      else if(sessionStorage.getItem('userName') !== null) return sessionStorage.getItem('userName');
      //Kein Ablaufdatum vorhanden
      else return null;
    },

    /**
     * Methode um zu berechnen wie lange ein Token noch gültig ist
     * @param date
     * @returns  Minuten bis zum Ablauf des Tokens | Minuten seit dem Ablauf des Tokens
     */
    compareDate(date){
      let now = new Date();
      let timeDiff = now - new Date(date);
      return (timeDiff*-1)/ 60000;
    }
  },
  name: "main"
}
</script>

<style>
@import '../css/style.css';
</style>