<template>
    <footer>
        <a href="https://www.zgg-bw.de" target="_blank" rel="noopener" aria-label='Copyright-Hinweis'>Baden-Württemberg ZGG © {{ aktuellesJahr }}</a>
    </footer>
</template>

<script>
    export default {
      data: function() {
        return {
          aktuellesJahr: new Date().getFullYear()
        }
      },
        name: "footer"
    }
</script>

<style>
    @import '../css/style.css';
</style>