
<template>
    <div class="templateBody">
      <div id="anwendungsheader">
        <bw_header></bw_header>
        <LoginTopNav></LoginTopNav>
      </div>
        <div id="maincontainer" class="clearfix">
          <div class="loginmain">
            <p v-if="visable" class="loginWarningMessage">{{message}}</p>
            <div class="loginmask">
              <button class="loginButton" v-on:click="tranferToLogin()">
                Login
              </button>
            </div>
          </div>
        </div>
        <bw_footer></bw_footer>
    </div>
</template>

<script>
    import bw_header from '../components/header'
    import LoginTopNav from '../components/LoginTopNav'
    import bw_footer from '../components/footer'
    import{authentification} from "../js/authentification/authentification.js";
    export default {
        components: {
          bw_header,
          LoginTopNav,
          bw_footer
        },
      data: function() {
        return {
          //Anzeigen von Fehlern
          message: this.$route.params.message,
          visable: this.$route.params.visable
        };
      },
      methods: {
          //Weiterleitung zum Geoportal
          tranferToLogin(){
            authentification.login();
          }
      }
    }

</script>

<style>
    @import '../css/style.css';
</style>
