import Vue from 'vue'
import App from './App.vue'
import router from './router/index.js'
import axios from 'axios'
import VueAxios from 'vue-axios'
import "@fontsource/eb-garamond";

Vue.use(VueAxios, axios);

import VueNavigationBar from 'vue-navigation-bar'
import 'vue-navigation-bar/dist/vue-navigation-bar.css'
Vue.component('vue-navigation-bar', VueNavigationBar);

import vuetify from '@/plugins/vuetify'

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  render: h => h(App)
}).$mount('#app');
