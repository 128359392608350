<template>
  <div id="kontaktDiv">
    <h1>
      Kontakt
    </h1>
    <h3> <span class="zeilenumbruchDanach">Zentrale Geschäftsstelle für</span>Grundstückswertermittlung Baden-Württemberg (ZGG)</h3> <br>
    <span>
            <p><b>Anschrift:</b><br></p>
            <p>
              Zentrale Geschäftsstelle für Grundstückswertermittlung Baden-Württemberg (ZGG)<br>
              Referat 24 Landesamt für Geoinformation und Landentwicklung<br>
              Büchsenstraße 54<br>
              70174 Stuttgart<br>
            </p>
            <p>
              E-Mail: <a href="mailto:lgl-viewer@lgl.bwl.de?subject=[Mail aus Uploadportal Grundsteuer B Baden-Württemberg]">lgl-viewer@lgl.bwl.de</a><br>
            </p>
          </span>
    <h3 class="notfirstH3"><span class="zeilenumbruchDanach">Bei technischen Problemem oder Störungen:</span>Information und Technik Nordrhein-Westfalen (IT.NRW)</h3><br>
    <span>
            <p><b>Anschrift:</b><br></p>
            <p>
              Referat 324 - Geoinformationszentrum<br>
              Mauerstraße 51<br>
              40476 Düsseldorf<br><br>
            </p>
            <p>
              E-Mail: <a href="mailto:limgeo@it.nrw.de?subject=[Mail aus Uploadportal Grundsteuer B Baden-Württemberg]">limgeo@it.nrw.de</a><br>
              Telefon: +49 (0211) 9449-6301<br>
              Homepage: <a href="https://www.it.nrw.de" target="_blank" rel="noopener">www.it.nrw.de</a><br>
            </p>
          </span>
  </div>
</template>

<script>
export default {
  name: "Kontaktcontent"
}
</script>

<style scoped>
@import '../css/style.css';
</style>