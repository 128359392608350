<template>
  <div class="templateBody">
    <div id="anwendungsheader">
      <bw_header></bw_header>
      <LoginTopNav></LoginTopNav>
    </div>
    <div id="maincontainer" class="clearfix">
      <ImpressumsContent></ImpressumsContent>
    </div>
    <bw_footer></bw_footer>
  </div>
</template>

<script>
import bw_header from '../components/header'
import LoginTopNav from '../components/LoginTopNav'
import ImpressumsContent from '../components/impressumscontent'
import bw_footer from '../components/footer'
export default {
  name: "impressum",
  components: {
    bw_header,
    LoginTopNav,
    ImpressumsContent,
    bw_footer
  }
}
</script>

<style scoped>
@import '../css/style.css';
</style>