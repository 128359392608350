import Vue from 'vue'
import Router from 'vue-router'
import Startpage from '../views/startseite.vue'
import Main from '../views/upload'
import impressum1 from '../views/impressum'
import impressum2 from '../views/impressum2'
import kontakt1 from '../views/kontakt'
import kontakt2 from '../views/kontakt2'
import{userstore} from '../router/userstore'
import async from "async";

Vue.use(Router);

/**
 * Methode um nach der CAS-Anmeldung jeden unberechtigten Aufruf in der Anwendung zu unterbinden
 * @param to
 * @param from
 * @param next
 */
const ifAuthenticated = async (to, from, next) => {
    //Ist user Authentifiziert
    userstore.isAuthenticated().then(function (response) {
        if(response.authorized){
            //Hole FME-Token
            userstore.getToken().then(function (fmeResponse) {
                //Wurde FME-Token zurückgeschickt
                if(fmeResponse.token){
                    // -> Zur Uploadseite mit gültigem Token und Ablaufdatum
                    next({name: "Upload", params: { token: fmeResponse.token, expiry: fmeResponse.expiry, validatePassword: response.validatePassword, grundsteuerRight: response?.grundsteuerRight, stadtbauRight: response?.stadtbauRight}});
                }else{
                    // -> Logout und Fehlermeldung für User
                    next({ name: 'Login', params: { message: fmeResponse.message, visable: true } });
                }
            });
        }else{
            // -> Logout und Fehlermeldung für User
            next({ name: 'Login', params: { message: response.message, visable: true } });
        }
    });
}

/**
 * Methode um vor jedem Seitenwechsel in der Anwendung die Authentifizierung zu überprüfen
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const stillAuthenticated = async (to, from, next) => {
    //Ist user Authentifiziert
    userstore.isAuthenticated().then(function (authenticated) {
        if(authenticated?.authorized){
            // -> Seite innerhalb der Anwendung
            next();
        }else{
            // -> Logout
            next('/login')
        }
    });
}

/**
 * Methode um je nach Authentifizierung zu entscheiden welche Kontaktsversion ein User sehen darf
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const stillAuthenticatedKontakt = async (to, from, next) => {
    //Ist user Authentifiziert
    userstore.isAuthenticated().then(function (authenticated) {
        if(authenticated?.authorized){
            // -> Seite innerhalb der Anwendung
            next({ name: 'Kontakt1'})
        }else{
            // -> Logout
            next({ name: 'Kontakt2'})
        }
    });
}

/**
 * Methode um je nach Authentifizierung zu entscheiden welche Impressumsversion ein User sehen darf
 * @param to
 * @param from
 * @param next
 * @returns {Promise<void>}
 */
const stillAuthenticatedImpressum = async (to, from, next) => {
    //Ist user Authentifiziert
    userstore.isAuthenticated().then(function (authenticated) {
        if(authenticated?.authorized){
            // -> Seite innerhalb der Anwendung
            next({ name: 'Impressum1'})
        }else{
            // -> Logout
            next({ name: 'Impressum2'})
        }
    });
}

export default new Router({
    //publicPath: '/bw_upload',
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        { path: '/login', name: "Login", component: Startpage},
        { path: '/upload', name: "Upload", component: Main, beforeEnter: stillAuthenticated},
        { path: '/assets/*'},
        //{ path: '/unberechtigt', name: "Unberechtigt", component: Unberechtigt},
        { path: '/callback', name: "Callback", beforeEnter: ifAuthenticated},
        { path: '/Impressum', name: "Impressum", beforeEnter: stillAuthenticatedImpressum},
        { path: '/Impressum', name: "Impressum1", component: impressum1, beforeEnter: stillAuthenticated},
        { path: '/Impressum', name: "Impressum2", component: impressum2},
        { path: '/Kontakt', name: "Kontakt", beforeEnter: stillAuthenticatedKontakt},
        { path: '/Kontakt', name: "Kontakt1", component: kontakt1, beforeEnter: stillAuthenticated},
        { path: '/Kontakt', name: "Kontakt2", component: kontakt2},
        // otherwise redirect to home
        { path: '/', redirect: '/login'},
        { path: '*', redirect: '/login'}
    ]
});
