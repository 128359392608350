<template>
    <div id="top_nav">
        <ul>
          <li>
            <router-link title="Upload" to="Upload">Upload</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Kontakt'}">Kontakt</router-link>
          </li>
          <li>
            <router-link :to="{ name: 'Impressum'}">Impressum</router-link>
          </li>
          <li>
            <a href="#" v-on:click="logoutUser" title="Abmelden" aria-label='Logout'>Abmelden</a>
          </li>
        </ul>
    </div>
</template>

<script>
    //import {router} from '../router/index';
    import {authentification} from "@/js/authentification/authentification"
    export default {
      name: "Navigation",
        methods:{
            logoutUser(){
              sessionStorage.removeItem('grundsteuerRight');
              sessionStorage.removeItem('stadtbauRight');
              sessionStorage.removeItem('validateright');
              sessionStorage.removeItem('validatePassword');
              sessionStorage.removeItem('grundsteuerPassword');
              sessionStorage.removeItem('stadtbauPassword');
              sessionStorage.removeItem('userName');
              authentification.logout();
            }
        },
      data () {
        return {
          publicPath: process.env.BASE_URL
      }
      }
    }
</script>

<style>
    @import '../css/style.css';
</style>