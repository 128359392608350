<template>
    <div id="startContent">
        <h2 class="starthl" aria-label='Willkommen beim Uploadportal Baden-Württemberg'>
          Willkommen beim Uploadportal Baden-Württemberg
        </h2>
        <div class="begruessungstext">
            <span>
              <span class="zeilenumbruchDanach">Bitte die hochzuladenden Dateien mittels „Drag and Drop“ in die vorgesehenen Schaltflächen hineinziehen</span>
              <span class="zeilenumbruchDanach">oder per Klick in das Feld aus dem eigenen Dateisystem auswählen. Die hochzuladenen Dateien müssen zwingend</span>
              <span class="zeilenumbruchDanach"> das UTF-8 Encoding verwenden, damit Umlaute und Sonderzeichen korrekt verarbeitet werden können und es nicht</span>
               zu unerwarteten Fehlern kommt.
            </span>
        </div>
    </div>
</template>

<script>
    export default {
        name: "wellcomeMessage"
    }
</script>
<style>
    @import '../css/style.css';
</style>
