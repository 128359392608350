import axios from "axios";
//import qs from 'qs'


export const authentification = {
    checkIfAuthenticated,
    login,
    getToken,
    logout
};

/**
 * Methode um die Authentifizierung des User sicher zu stellen
 * @returns {Promise<AxiosResponse<any>>}
 */
async function checkIfAuthenticated() {
    let url;
    if(location.hostname !== "apc25789.it.nrw.de"){
        location.host === "www.brw-daten.gutachterausschuesse-bw.de"
            ?url='https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload-api/authenticated'
            :url='https://www.gistest1.nrw.de/borisbw-upload-api/authenticated'
    }else{
        url = "https://apc25789.it.nrw.de/authenticated";
    }
    return axios({
        method: 'get',
        url: url,
        dataType: 'json',
        withCredentials: true
    }).then(function (response) {
        return response?.data || null;
    }).catch(function (err) {
        return err?.response?.data || null;
    });
}

/**
 * Methode um sich über das Geoportal einzuloggen
 * @returns {Promise<void>}
 */
async function login() {
    let url;
    if (location.hostname !=="apc25789.it.nrw.de") {
        location.host === "www.brw-daten.gutachterausschuesse-bw.de"
            ? url = "https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload-api/cas?relaystate=https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload/callback"
            : url = "https://www.gistest1.nrw.de/borisbw-upload-api/cas?relaystate=https://www.gistest1.nrw.de/borisbw-upload/callback"
    } else {
        url = "https://apc25789.it.nrw.de/cas?relaystate=https://apc25789.it.nrw.de:8443/callback"
    }
    window.location.href =url;
}

/**
 * Methode um einen FME-Token zu erhalten
 * @returns {Promise<*>}
 */
async function getToken() {
    let url;
    if (location.hostname !=="apc25789.it.nrw.de") {
        location.host === "www.brw-daten.gutachterausschuesse-bw.de"
            ? url = 'https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload-api/fmeToken'
            : url = 'https://www.gistest1.nrw.de/borisbw-upload-api/fmeToken'
    } else {
        url = "https://apc25789.it.nrw.de/fmeToken";
    }
    return axios({
        method: 'get',
        url: url,
        dataType: 'json',
        withCredentials: true
    }).then(function (response) {
        return response?.data || null;
    }).catch(function (err) {
        return err?.response?.data || null;
    });
}

/**
 * Methode um sich über das Geoportal auszuloggen
 * @returns {Promise<void>}
 */
async function logout() {
    let url;
    if (location.hostname !=="apc25789.it.nrw.de") {
        location.host === "www.brw-daten.gutachterausschuesse-bw.de"
            ? url = "https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload-api/logout?relaystate=https://www.brw-daten.gutachterausschuesse-bw.de/borisbw-upload/login"
            : url = "https://www.gistest1.nrw.de/borisbw-upload-api/logout?relaystate=https://www.gistest1.nrw.de/borisbw-upload/login"
    } else {
        url = "https://apc25789.it.nrw.de/logout?relaystate=https://apc25789.it.nrw.de:8443/login"
    }
    window.location.href =url;
}