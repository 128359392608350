import{authentification} from "../js/authentification/authentification.js";

export const userstore = {
    checkIfAuthenticated,
    isAuthenticated,
    getToken
};

/**
 * Methode um zu uberpruefen ob ein User noch die Authentifizierung hat
 * @param router
 * @returns {Promise<any>}
 */
async function checkIfAuthenticated(router) {
    //Starte Abfragerequest
    authentification.checkIfAuthenticated().then(function (response){
        //Ist gueltiger User
            if(!response?.authorized){
                if(router.history.current.path !== '/login' && router.history.current.path !== '/unberechtigt') router.push('/login')
            }
        }
    )
}

/**
 * Methode um den Authentifizierungsresponse zu verarbeiten
 * @returns {Promise<AxiosResponse<*>>}
 */
async function isAuthenticated() {
    //Ist Authentifiziert?
    return authentification.checkIfAuthenticated().then(function (response){
        //Hat der Request geklappt?
        if(response?.authorized){
            sessionStorage.setItem('grundsteuerRight', response?.grundsteuerRight || false);
            sessionStorage.setItem('stadtbauRight', response?.stadtbauRight || false);
            // {authorized: true|false}
            return {authorized: response.authorized};
        //Request fehlgeschlagen mit Grund
        }else if(response?.message){
            let message;
            switch (response?.code){
                case 401:
                case 403: message= "Sie haben nicht die Berechtigung diese Anwendung zu nutzen";break;
                case 400:
                case 500: message= "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum (Fehlercode "+response.code+")";break;
                //Anderer Http-Error-Code
                default: message= "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum";break;
            }
            return {authorized: false, message: message};
         //Request fehlgeschlagen ohne Grund
        }else{
            return {authorized: false, message: "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum"};
        }
    });
}

/**
 * Methode um den FME-Token-Response zu verarbeiten
 * @returns {Promise<*>}
 */
async function getToken() {
    //Hole Token
    return authentification.getToken().then(function (response){
        //Wurde Token mitgeschickt
        if(response?.token && response?.expiry && response.validatePassword){
            //Speichere Token und Ablaufdatum
            sessionStorage.setItem('token', response.token);
            sessionStorage.setItem('expiry', response.expiry);
            sessionStorage.setItem('validatePassword', response.validatePassword);
            sessionStorage.setItem('grundsteuerPassword', response.grundsteuerPassword);
            sessionStorage.setItem('stadtbauPassword', response.stadtbauPassword);
            if(response?.userName) sessionStorage.setItem('userName', response.userName);
            return {token: response.token, expiry:response.expiry, validatePassword: response.validatePassword,
                grundsteuerPassword: response?.grundsteuerPassword,stadtbauPassword: response?.stadtbauPassword, userName: response?.userName};
        //Request fehlgeschlagen mit Grund
        }else if(response?.message){
            let message;
            switch (response?.code){
                case 401:
                case 403: message= "Sie haben nicht die Berechtigung Daten hochzuladen";break;
                case 500: message= "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum (Fehlercode "+response.code+")";break;
                //Anderer Http-Error-Code
                default: message= "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum";break;
            }
            return {token: false, message: message};
        //Request fehlgeschlagen ohne Grund
        }else{
            return {token: false, message: "Ein Fehler ist aufgetreten bitte kontaktieren Sie das Geoinformationszentrum"};
        }
    });
}


