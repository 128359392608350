<template>
    <header>
        <div id="logo_wrapper">
            <div id="logo_left">
                <img id="AnwendungsLogo" src="../assets/logo_boris-bw.svg" alt="Logo AnwendungsLogo">
<!--              <img id="IT_NRW-Logo" src="../assets/logo_it_nrw.png" alt="Logo IT.NRW">-->
            </div>
          <h1 id="AnwendungsTitel">Baden-Württemberg<span class="zeilenumbruchDanach"></span>
            <h2 id="AnwendungsTitel2">Uploadportal</h2>
          </h1>
          <div id="logo_right">
            <a id="ministeriumsLink" href="https://www.mlw.baden-wuerttemberg.de" target="boris" title="Zur Startseite Ministerium für Landesentwicklung und Wohnen Baden‑Württemberg">
              <img id="BW-Logo" src="../assets/logo-bawue.png" alt="Logo GeobasisNRW">
              <h2>Ministerium für Landesentwicklung und<span class="zeilenumbruchDanach"></span>Wohnen Baden‑Württemberg</h2>
            </a>
          </div>
        </div>
    </header>
</template>

<script>
    export default {
        name: "header"
    }
</script>

<style>
    @import '../css/style.css';
</style>