import axios from "axios";
//FME-Urls
import services from "@/config/services";

export const fmeManager = {
    fmeTransfer,
    fmegetJobStatus,
    fmegetJobResult
};
/**
 * Methode um FME-Job zu starten
 * @param apiToken
 * @param projectname
 * @param workspace
 * @param data
 * @param resulturl
 * @returns {Promise<AxiosResponse<any>>}
 */
async function fmeTransfer(key , username, apiToken , projectname, workspace, data, resulturl, jahr, aktualisierung) {
    let url = services.servername + "/fmerest/v3/transformations/submit/"+projectname+"/"+workspace+".fmw";
    //Datentoepfe
    let publishedParameters;

    //Städebau hat zusätzliche puplic-Parameter
    if(projectname === services.projectname_stadtbau){
        publishedParameters = [
            {
                "name": "_key",
                "value": key
            },{
                "name": "_uploaded_file",
                "value": "$(FME_SHAREDRESOURCE_TEMP)"+data.path+data.name
            },{
                "name": "_answer",
                "value": "$(FME_SHAREDRESOURCE_TEMP)"+resulturl
            },{
                "name": "_jahr",
                "value": jahr
            },{
                "name": "_logged_in_username",
                "value": username
            }
        ];
        if(workspace === services.workspace_upload1){
            publishedParameters.push({
                "name": "_aktualisierung",
                "value": aktualisierung
            })
        }
    }else{
        publishedParameters = [
            {
                "name": "_key",
                "value": key
            },{
                "name": "_uploaded_file",
                "value": "$(FME_SHAREDRESOURCE_TEMP)"+data.path+data.name
            },{
                "name": "_answer",
                "value": "$(FME_SHAREDRESOURCE_TEMP)"+resulturl
            },{
                "name": "_jahr",
                "value": 2023
            },{
                "name": "_logged_in_username",
                "value": username
            }
        ];
    }
    return axios({
        url: url,
        method: 'POST',
        data: {
            publishedParameters: publishedParameters,
        },
        withCredentials: true,
        //FME-Api-Token
        headers: {
            'Authorization': "fmetoken token="+apiToken
        },
    }).then(function (result) {
        return result
    })
}

/**
 * Methode um nach dem Status eines FME-Jobs zu fragen
 * @param apiToken
 * @param jobID
 * @returns {Promise<AxiosResponse<any>>}
 */
async function fmegetJobStatus(apiToken, jobID) {
    let url = services.servername +"/fmerest/v3/transformations/jobs/id/"+jobID
    return axios({
        url: url,
        method: 'get',
        withCredentials: true,
        //FME-Api-Token
        headers: {
            'Authorization': "fmetoken token="+apiToken
        },
    }).then(function (result) {
        return result
    })
}

/**
 * Methode um die antwort.zip eines FME-Jobs abzuholen
 * @param apiToken
 * @param result
 * @returns {Promise<AxiosResponse<any>>}
 */
async function fmegetJobResult(apiToken, result) {
    let url = services.servername +'/fmerest/v3/resources/connections/FME_SHAREDRESOURCE_TEMP/filesys'+result+'?accept=contents"';
    return axios({
        url: url,
        method: 'GET',
        withCredentials: true,
        //FME-Api-Token
        headers: {
            'Authorization': "fmetoken token="+apiToken
        }
    }).then(function (result) {
        return result
    })
}
