<template>
  <div id="app" class="jumbotron">
    <router-view></router-view>
  </div>
</template>

<script>
//import{authentification} from "./js/authentification/authentification.js";
import{userstore} from './router/userstore'
export default {
  name: 'app',
  async created () {
    let router = this.$router;
    //Regelmaessige Uberpruefung ob der User noch die Berechtigung besitzt
    this.authCheck = setInterval(function() {
      if(router.history.current.path !== '/login' && router.history.current.path !== '/unberechtigt'
          && router.history.current.name !== 'Impressum2' && router.history.current.name !== 'Kontakt2') userstore.checkIfAuthenticated(router);
    },3000);
  },
};
</script>
